import React, { useState, useEffect } from "react";
import Suggestions from "./Suggestions/Suggestions";
import ErrorBoundary from "../Errorlog/ErrorBoundary";
import axios from "axios";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import Tooltip from "@material-ui/core/Tooltip";
import UserStore from "../../UserStore";
import {
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import "./SearchBar.scss";

export default function SearchBar(props) {
  let [q, setQ] = useState("");
  let [suggestions, setSuggestions] = useState([]);
  let [showSuggestions, setShowSuggestions] = useState(false);
  const { instance, accounts } = useMsal();

  // eslint-disable-next-line
  const [uriError, setUriError] = useState(false);
  const [isSelectedcheckbob, setisSelectedcheckbob] = useState(UserStore.isSelectedcheckbob);
  let [accountsValue] = accounts;
  const API_endpoint = process.env.REACT_APP_API_ENDPOINT;
  // eslint-disable-next-line

  const onSearchHandler = () => {
    if((window.location.href).includes("search?q")) {
      props.setFilters([]);
    }
    props.postSearchHandler(document.getElementById("search-box").value);
    setShowSuggestions(false);
  };

  const suggestionClickHandler = (s) => {
    document.getElementById("search-box").value = s;
    setShowSuggestions(false);
    props.postSearchHandler(s);
  };

  const onEnterButton = (event) => {
    if (event.keyCode === 13) {
      onSearchHandler();
    }
  };

  const onChangeHandler = () => {
    var searchTerm = document.getElementById("search-box").value;
    setQ(searchTerm);
    if(searchTerm === null || searchTerm === undefined ||searchTerm.length === 0) {
      setisSelectedcheckbob(false);
      UserStore.setisSelectedcheckbox(false);
    }
    setShowSuggestions(true);
    // use this prop if you want to make the search more reactive
    if (props.searchChangeHandler) {
      props.searchChangeHandler(searchTerm);
    }
  };

  async function suggestionsCall() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue,
    });
    const accessToken = response.accessToken;

    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const res = await axios.get(
      API_endpoint + `/GetKmSuggestions?searchText=${q}`,
      config
    );
    setSuggestions(res.data.value.results);
  }

  useEffect(
    (_) => {
      const timer = setTimeout(() => {
        if (q === "") {
          setSuggestions([]);
        } else {
          suggestionsCall();
        }
      }, 300);
      return () => clearTimeout(timer);
      // eslint-disable-next-line
    },
    [q, props, accountsValue]
  );

  var suggestionDiv;
  if (showSuggestions) {
    suggestionDiv = (
      <Suggestions
        suggestions={suggestions}
        suggestionClickHandler={(s) => suggestionClickHandler(s)}
      ></Suggestions>
    );
  } else {
    suggestionDiv = <div></div>;
  }
  const handleChange = () => {
    setisSelectedcheckbob(!isSelectedcheckbob);
    UserStore.setisSelectedcheckbox(!isSelectedcheckbob);
    const chaneValue = !isSelectedcheckbob;
    if(chaneValue) {
        document.getElementById("search-box").value = `"${document.getElementById("search-box").value}"`
    }else{
        document.getElementById("search-box").value= document.getElementById("search-box").value.replace(/"/g,'')
    }

    var searchTerm = document.getElementById("search-box").value;
    setQ(searchTerm);
    // use this prop if you want to make the search more reactive
    if (props.searchChangeHandler) {
      props.searchChangeHandler(searchTerm);
    }
  };
  return (
    <ErrorBoundary>
      <div>
        <div className="input-group" onKeyDown={(e) => onEnterButton(e)}>
        <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={isSelectedcheckbob} onChange={handleChange} />
                }
                className='ExactLabel'
                label="Exact"
                style={{ position: "fixed", left: "73vw", top: "unset", zIndex: '10', marginTop: '1vw'}}
              />
            </FormGroup>
          <div className="suggestions">
            <Tooltip title="Please type to search for documents" arrow>
              <TextField
                autoComplete="off" // setting for browsers; not the app
                type="text"
                id="search-box"
                className="form-control"
                placeholder="Search documents by keywords"
                onChange={onChangeHandler}
                defaultValue={props.q}
                onBlur={() => setShowSuggestions(false)}
                onClick={() => {
                  setShowSuggestions(true);
                }}
                InputProps={{ disableUnderline: true }}
              ></TextField>
            </Tooltip>

           
            <div className="input-group-btn resultPageSearch" title="Search">
              <button
                className="btn search-button"
                type="submit"
                onClick={onSearchHandler}
              >
                <svg
                  className="search-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    id="Search_Icon"
                    data-name="Search Icon"
                    d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,0,1,10.2,0A5.022,5.022,0,0,1,7.1,12.025Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>
          </div>
          {suggestionDiv}
        </div>
      </div>
    </ErrorBoundary>
  );
}
