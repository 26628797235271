import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import AppHeader from '../../components/AppHeader/AppHeader';
import SearchBar from '../../components/SearchBar/SearchBar';
import ErrorBoundary from '../../components/Errorlog/ErrorBoundary';
import { loginRequest } from "../../authConfig";
import { ToastProvider } from 'react-toast-notifications';
import UserStore from '../../UserStore';

import axios from 'axios';

import "./Home.scss";
import "../../pages/Search/Search.scss";
import { Button } from "@material-ui/core";

export default function Home() {
  const history = useHistory(); 
  const { instance, accounts } = useMsal();
  let [accountsValue] = accounts;
  const [globalAdmin, setGlobalAdmin] = useState(false);
  const API_endpoint = process.env.REACT_APP_API_ENDPOINT;
  UserStore.setisSelectedcheckbox(false);
  const navigateToSearchPage = (q) => {
    let space = q.split(" ");

    if (!q || q === '' || q === ' ' || space.length === q.length) {
      q = '*'
    }
    history.push('/search?q=' + q);
    UserStore.setInitialQ(q);
  }

  useEffect(() => {
    if(accountsValue) { checkGlobalAdmin(); }
    //eslint-disable-next-line 
  }, [accountsValue]);

  const Login = () => {
    const { instance } = useMsal();

    instance.loginRedirect(loginRequest).catch(e => {
        // console.log(e);
    });
  }
  
  function navigateToRegexPage() {
    history.push('/regex');
  }

  // --------------------------Start:Funtion: check logged in user is global admin------------------
  async function checkGlobalAdmin() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue
  });
  const accessToken = response.accessToken;

  const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
  };

    const res = await axios.get(API_endpoint + `/IsUserKmGlobalAdmin`, config);
    setGlobalAdmin(res.data);
  }
 
  return (
    <div>
      <AuthenticatedTemplate>
      <ErrorBoundary>
        <div className="home-page" style={{ "backgroundImage": "linear-gradient(rgba(255,255,255,.07), rgba(255,255,255,.07)),url(/images/bg_image.svg)" }}>
          <AppHeader nextPage={'HomePage'}/>
          <main className="main main--home">
            <div className="home-page-wrapper">
              <div className="row home-search">
                <div className="home-searchbar">
                <ToastProvider>
                  <SearchBar postSearchHandler={navigateToSearchPage}></SearchBar>
                </ToastProvider>
                <div className="regex" style={globalAdmin ? {display: 'flex'} : {display: 'none'}}>
                  <Button className="regex-btn" onClick={navigateToRegexPage}>Create RegEx</Button>
                </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </ErrorBoundary>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login/>
      </UnauthenticatedTemplate>
    </div>
  );
};
