import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation, useHistory } from "react-router-dom";
import AppHeader from '../../components/AppHeader/AppHeader';
import Results from '../../components/Results/Results';
import Pager from '../../components/Pager/Pager';
import Facets from '../../components/Facets/Facets';
import SearchBar from '../../components/SearchBar/SearchBar';
import ErrorBoundary from '../../components/Errorlog/ErrorBoundary';
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ToastProvider } from 'react-toast-notifications';
import UserStore from '../../UserStore';

import "./Search.scss";

export default function Search() {

  let location = useLocation();
  let history = useHistory();
  const { instance, accounts } = useMsal();
  let [accountsValue] = accounts;
  const [results, setResults] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [q, setQ] = useState(UserStore.initialQ ?? "");
  const [top] = useState(new URLSearchParams(location.search).get('top') ?? 10);
  const [skip, setSkip] = useState(new URLSearchParams(location.search).get('skip') ?? 0);
  const [filters, setFilters] = useState([]);
  const [facets, setFacets] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState('relevance');

  let resultsPerPage = top;

  const API_endpoint = process.env.REACT_APP_API_ENDPOINT;
  const groupBy = (arr, key) => {
    const initialValue = {};
    return arr.reduce((acc, cval) => {
      const myAttribute = cval[key];
      acc[myAttribute] = [...(acc[myAttribute] || []), cval]
      return acc;
    }, initialValue);
  };  
  
  // creates filters in odata syntax
  const createFilterExpression = (filterList) => {
    let filterExpressions = [];
    if(filterList.length) {
    
    const facetsGroup = groupBy(filterList, "field");
   for(let input in facetsGroup){
     if (input === 'metadata_location') {
        filterExpressions.push(facetsGroup[input].map(e => `${input} eq '${e.value}'`).join(" or "));
     }
     if (input === 'language') {
      filterExpressions.push(facetsGroup[input].map(e => `${input} eq '${e.value}'`).join(" or "));
      }
    if (input === 'metadata_storage_file_extension_lower') {
      filterExpressions.push(facetsGroup[input].map(e => `${input} eq '${e.value}'`).join(" or "));
    }
    if (input === 'metadata_creation_date') {
      filterExpressions.push(facetsGroup[input].map(e => `(${input} ge ${e.value}-01-01T00:00:00Z and ${input} le ${e.value}-12-31T23:59:59Z)`).join(" or "));
    }
    if (input === 'metadata_project') {
      filterExpressions.push(facetsGroup[input].map(e => `${input} eq '${e.value}'`).join(" or "));
    }
    if (input === 'metadata_discipline') {
      filterExpressions.push(facetsGroup[input].map(e => `${input} eq '${e.value}'`).join(" or "));
    }
    if (input === 'metadata_businessunit') {
      filterExpressions.push(facetsGroup[input].map(e => `${input} eq '${e.value}'`).join(" or "));
    }
   }
   const addbraces = `(${ filterExpressions.join(') and (')})`;
    return addbraces;
  }else {
    return filterExpressions.join(' and ');
  }
  }

  // If search term is empty, search everything
  if (!q || q === "" || q === " " || !q.trim().length) {
    setQ('*');
  }

  let apiFilters = encodeURIComponent(createFilterExpression(filters, facets).toString());
  const handleChange = (e) => {
    setSort(e.target.value);
  }

  async function searchResultsCall() {
    setIsLoading(true);
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue
    });
    const accessToken = response.accessToken;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    if (currentPage > 0 && sort === 'recent') {
      let res = await axios.get(
        API_endpoint + `/GetKmSearch?searchText=${encodeURIComponent(q)}&size=${top}&skip=${skip}&filter=${apiFilters}&orderby=metadata_creation_date desc`,
        config
      );
      setResults(res.data.value);
      setFacets(res.data.facets);
      setResultCount(res.data.totalCount);
      setIsLoading(false);
    } else {
      let res = await axios.get(
        API_endpoint + `/GetKmSearch?searchText=${encodeURIComponent(q)}&size=${top}&skip=${skip}&filter=${apiFilters}`,
        config
      );

      setResults(res.data.value);
      setFacets(res.data.facets);
      setResultCount(res.data.totalCount);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if(accountsValue) { searchResultsCall(); }
    //eslint-disable-next-line 
  }, [q, top, skip, currentPage, sort, accountsValue]);
  
  useEffect(() => {
    setCurrentPage(1);
    if(filters.length === 0) {
      searchResultsCall();
    }
    
  }, [filters]);
  
  // pushing the new search term to history when q is updated
  useEffect(() => {
    history.push('/search?q=' + q);
    setCurrentPage(1);
    setFilters([]);
    // eslint-disable-next-line 
  }, [q]);

  let postSearchHandler = (searchTerm) => {
    setQ(searchTerm);
  }

  let updatePagination = (newPageNumber) => {
    setCurrentPage(newPageNumber);
    setSkip((newPageNumber - 1) * top);
  }

  var body;
  if (isLoading) {
    body = (
      <div className="col-md-9">
        {/* <CircularProgress /> */}
      </div>);
  } else {
    body = (
      <div className="col-md-9" style={{ "padding": "0" }}>
        <div className="sortBy">
          <p className="sort-dorpdown">Sort by:</p>
          <FormControl variant="outlined">
            <Select labelId="demo-simple-select-label" id="demo-simple-select" defaultValue={"relevance"} value={sort} onChange={handleChange}>
              <MenuItem value={"relevance"}>Relevance</MenuItem>
              <MenuItem value={"recent"}>Recent</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Results documents={results} top={top} skip={skip} count={resultCount} q={q}></Results>
        <Pager className="pager-style" currentPage={currentPage} resultCount={resultCount} resultsPerPage={resultsPerPage} setCurrentPageValue={updatePagination}></Pager>
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <div className="search-page">
        <div style={{position:"fixed", width:"100vw", zIndex:"10"}}>
          <AppHeader nextPage={'searchResultPage'}/>
        </div>
        <LoadingOverlay active={isLoading} spinner text='Loading your content...'>
          <main className="main main--search container-fluid search-main">
            <div className="row" style={{"marginRight": "0" }}>
              <div className="col-md-3">
                <div className="search-bar" style={{position:"fixed", zIndex:"100", marginLeft:"25vw", width:"52.1vw"}}>
                <ToastProvider>
                  <SearchBar postSearchHandler={postSearchHandler} q={q} setFilters={setFilters} ></SearchBar>
                </ToastProvider>
                </div>
                <Facets facets={facets} filters={filters} setFilters={setFilters} isLoading={isLoading} applayFilters={searchResultsCall}></Facets>
              </div>
              {body}
            </div>
          </main>
        </LoadingOverlay>
      </div>
    </ErrorBoundary>
  );
}