import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox 
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styled from "styled-components";
import moment from "moment";
import "./CheckboxFacet.scss";
import getLanguage from '../../Results/Result/getLanguage';

export default function CheckboxFacet(props) {
  
  let [isExpanded, setIsExpanded] = useState(false);
  let new_facetArray = props.values;

  const checkboxes = new_facetArray.map((facetValue, i) => {
    let checkboxValue = facetValue.value;
    let checkboxCount = facetValue.count;
    if (moment(checkboxValue, moment.ISO_8601, true).isValid()) {
      checkboxValue = moment(checkboxValue).format("YYYY");
    }

    let isSelected = props.selectedFacets.some(
      (facet) => facet.value === checkboxValue
    );
    return (
      <>
        {checkboxValue && 
        
        (<FormControlLabel control={<Checkbox  
          onClick={() => {
                    isSelected = !isSelected;
                    if(!isSelected) {
                        props.removeFilter({field: props.name,value: checkboxValue});
                    } else {
                        props.addFilter(props.name, checkboxValue);
                    }
                }}
            checked={isSelected}
              />} 
        value={`${checkboxValue} (${checkboxCount})`}
        label={`${getLanguage(checkboxValue)} (${checkboxCount})`}
        style={{marginLeft: '1.5vw'}}
        />)
        }
      </>
    );
  });

  return (
    <div>
      <FacetListItem
        disableRipple={true}
        button
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ borderBottom: "1px solid lightgrey", paddingLeft: "1.5vw !important", marginTop: "0.2vw", marginBottom: "0.2vw" }}
      >
        <ListItemText primary={props.mapFacetName(props.name)} />
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </FacetListItem>
      <Collapse in={isExpanded} component="div">
        <FacetValuesList>
          <FormControl component="fieldset">            
              {checkboxes}
          </FormControl>
        </FacetValuesList>
      </Collapse>
    </div>
  );
}

const FacetListItem = styled(ListItem)({
  paddingLeft: "1.5vw !important",
});

const FacetValuesList = styled(List)({
  maxHeight: 340,
  overflowY: "auto !important",
  marginRight: "18px !important",
});