import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Details from '../../../pages/Details/Details';
import getDocIcon from './DocType';
import getLanguage from './getLanguage';
import saveAs from "file-saver";
import axios from 'axios';
import moment from 'moment';
import { loginRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import { getAppInsights } from '../../../TelemetryService';
import UserStore from '../../../UserStore';
import Tooltip from '@material-ui/core/Tooltip';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Highlighter from "react-highlight-words";
import './Result.scss';

export default function Result(props) {
    const API_endpoint = process.env.REACT_APP_API_ENDPOINT;
    const [show, setShow] = useState(false);
    const [ isActive, setActive] = useState(true);
    const { instance, accounts } = useMsal();
    let [ accountsValue ] = accounts;
    let language = getLanguage(props.document.language);
    let discipline = (props.document.metadata_discipline);
    let project = (props.document.metadata_project);
    let fileType = props.document.metadata_storage_file_extension_lower;
    var docType = getDocIcon(fileType);

    let isCAD = (fileType === '.dwg' || fileType === '.dgn' || fileType === '.DWG' || fileType === '.DGN');
    let content = isCAD ? props.document.dwg_dgn_content : props.document.translated_content;
    let searchTag = [];
    let searchQ = props.q;

    if(searchQ !== '*') {
        // eslint-disable-next-line
      searchQ = searchQ.trim();
      if((searchQ.startsWith("\"") && searchQ.endsWith("\""))) {
        if(searchQ.search(/\bAND\b/) > 0 || searchQ.search(/\bOR\b/) > 0 || searchQ.search(/\bNOT\b/) > 0){
          if(searchQ.search(/\bAND\b/) > 0 || searchQ.search(/\bOR\b/) > 0){
            searchTag = searchQ.replace(new RegExp(/\bAND\b/), "regexOperator").replace(new RegExp(/\bOR\b/), "regexOperator").replace(/"/g,'regexOperator').toLowerCase().split("regexoperator");
            for(let index in searchTag) {
              searchTag[index] = searchTag[index].trim();
            }
            searchTag = searchTag.filter(function (obj) {
              // obj = obj.trim();
              return !['',' ',null, undefined].includes(obj)
            });
          }else{
            searchTag = searchQ.replace(/"/g,'regexOperator').split("NOT")[0].toLowerCase().split("regexoperator");
            for(let index in searchTag) {
              searchTag[index] = searchTag[index].trim();
            }
            searchTag = searchTag.filter(function (obj) {
              obj = obj.trim();
              return !['',' ',null, undefined].includes(obj) });
          }
        }else {
          const searchWord = searchQ.substring(1, searchQ.length-1).trim();
          searchTag.push(searchWord + "");
        }

      } else {
        // eslint-disable-next-line
        searchTag = searchQ.toLowerCase().replace(/[\n\t\s]+/, " ").split(" ");
        searchTag = searchTag.filter(function (obj) { return !['',' ',null, undefined].includes(obj) });

      }
    } else {
      searchTag = '';
    }
    let newContent;
    if(content && searchTag) {
      let part1 = content.split(new RegExp(`(${searchTag[0]})`, 'gi'), 1);
      newContent = content.replace(part1, '');
      for(var i=1; i<searchTag.length; i++) {
        if(newContent === '') {
          part1 = content.split(new RegExp(`(${searchTag[i]})`, 'gi'), 1);
          newContent = content.replace(part1, '');
        }
      }
    }
    let lengthArray = [];
    let oneWord = [];
    let twoWord = [];
    let threeWord = [];
    let fourWord = [];
    let fiveWord = [];
    let sixWord = [];
    let sevenWord = [];
    let eightWord = [];
    let nineWord = [];
    let tenWord = [];
    let moreWord = [];
    if (props.document.keyphrases.length) {
      props.document.keyphrases.map(function (name) {
        if (name.replace(/[\n\t\s]+/g, " ").split(" ").length > 10) {
          name = name.slice(0, name.length / 2);
        }

        name = name.replace(/[\n\t\s]+/g, " ");
        switch (name.replace(/[\n\t\s]+/g, " ").split(" ").length) {
          case 1:
            oneWord.push(name);
            break;
          case 2:
            twoWord.push(name);
            break;
          case 3:
            threeWord.push(name);
            break;
          case 4:
            fourWord.push(name);
            break;
          case 5:
            fiveWord.push(name);
            break;
          case 6:
            sixWord.push(name);
            break;
          case 7:
            sevenWord.push(name);
            break;
          case 8:
            eightWord.push(name);
            break;
          case 9:
            nineWord.push(name);
            break;
          case 10:
            tenWord.push(name);
            break;
          default:
            moreWord.push(name);
            break;
        }

        lengthArray = [
          ...oneWord.sort(),
          ...twoWord.sort(),
          ...threeWord.sort(),
          ...fourWord.sort(),
          ...fiveWord.sort(),
          ...sixWord.sort(),
          ...sevenWord.sort(),
          ...eightWord.sort(),
          ...nineWord.sort(),
          ...tenWord.sort(),
          ...moreWord.sort(),
          // ...twoWord.sort().reverse(),
          // ...threeWord.sort().reverse(),
        ];


       /* logic for replacing the unprintable characters from the key phrases*/
        for(let i in lengthArray) {
          lengthArray[i] = lengthArray[i].replace(/[^a-zA-Z0-9~`!@#$%^&*()-_+=\s\{\}\[\]|\\:;"'<,>.?/]/gi, '');
          lengthArray[i] = lengthArray[i].trim().replace(/  +/g, ' ');
        }

        lengthArray = lengthArray.filter(f => {
           if(f.length !== 0)
            return f;
        })
        return lengthArray;
      });
    }


    let tagList = lengthArray.map(function(name, i){
        return <div className="tag" key={`${name.toLowerCase()} ${i}`}>
                    <span className="tag-name">{name.toLowerCase()}</span>
                </div>
    })

    let dateTime = props.document.metadata_creation_date;
    let datestring = moment(dateTime).format('DD MMM YYYY');
    if (dateTime == null) {
        datestring = "NA";
    }

    const handleClose = () => setShow(false);

    const handleShow = () => {
      setShow(true);
      let docID = props.document.document_id;
      let loggedInUser = UserStore.loggedInUser;
      getAppInsights().trackEvent({name: "Click",
        properties: {
          SearchServiceName: process.env.REACT_APP_SEARCH_SERVICE_NAME,
          ClickedDocId: docID,
          Action: "Preview",
          Userid: loggedInUser,
          language: props.document.language,
          metadata_businessunit: props.document.metadata_businessunit,
          metadata_creation_date: props.document.metadata_creation_date,
          metadata_discipline: props.document.metadata_discipline,
          metadata_location: props.document.metadata_location,
          metadata_project: props.document.metadata_project,
          metadata_storage_file_extension: props.document.metadata_storage_file_extension,
          metadata_storage_file_extension_lower: props.document.metadata_storage_file_extension_lower,
          metadata_storage_name: props.document.metadata_storage_name,
          metadata_storage_path: props.document.metadata_storage_path,
          previous_md5_content: props.document.previous_md5_content,
        }
      });
    }

    const expandSeemore = () => {
        setActive(!isActive);
    };

    let taglistStyle = {
        "height": "2.5vw",
        "marginLeft": "1%"
    }

    let taglistStyle1 = {
        "height": "7.8vw",
        "marginLeft": "1%"
    }

    const findChunksSearch = ({
      autoEscape,
      caseSensitive,
      sanitize,
      searchWords,
      textToHighlight
    }) => {
      const chunks = [];
      const textLow = textToHighlight.toLowerCase();
      const sep = /[\s~`!@#$%^&*()-_+=\{\}\[\]|\\:;"'<,\/>.?]/;
      const singleTextWords = textLow.split(sep);

      // It could be possible that there are multiple spaces between words
      // Hence we store the index (position) of each single word with textToHighlight
      let fromIndex = 0;
      const singleTextWordsWithPos = singleTextWords.map(s => {
        const indexInWord = textLow.indexOf(s, fromIndex);
        fromIndex = indexInWord;
        return {
          word: s,
          index: indexInWord
        };
      });

      // Add chunks for every searchWord
      searchWords.forEach(sw => {
        const swLow = sw.toLowerCase();
        // Do it for every single text word
        singleTextWordsWithPos.forEach(s => {
          if (s.word === swLow) {
            const start = s.index;
            const end = s.index + swLow.length;
            chunks.push({
              start,
              end
            });
          }
        });

        // The complete word including whitespace should also be handled
        if(swLow.split(" ").length > 1) {
        for(let index in textLow) {
          let start = parseInt(index);
          let end = parseInt(index) + swLow.length;
          let sub = textLow.substring(start,end);
          let remain = textLow.substring(start,end+1);
          if(sub === swLow && (/[^a-zA-Z0-9]/.test( remain[remain.length - 1] ))) {
            chunks.push({
              start,
              end
            });
          }
        }
      }
      });


      return chunks;
    };

    async function downloadFile () {

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: [process.env.REACT_APP_API_SCOPE],
        account: accountsValue
      });

      const accessToken = response.accessToken;

      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };

      let path = props.document.metadata_storage_path;

      let res = await axios.get(
        API_endpoint + `/GenerateKmSasToken?fileStoragePath=${encodeURIComponent(path)}`,
          config
        );

      let docID = props.document.document_id;
      let loggedInUser = UserStore.loggedInUser;
      console.log(props);
      getAppInsights().trackEvent({name: "Click",
        properties: {
          SearchServiceName: process.env.REACT_APP_SEARCH_SERVICE_NAME,
          ClickedDocId: docID,
          Action: "Download",
          Userid: loggedInUser,
          language: props.document.language,
          metadata_businessunit: props.document.metadata_businessunit,
          metadata_creation_date: props.document.metadata_creation_date,
          metadata_discipline: props.document.metadata_discipline,
          metadata_location: props.document.metadata_location,
          metadata_project: props.document.metadata_project,
          metadata_storage_file_extension: props.document.metadata_storage_file_extension,
          metadata_storage_file_extension_lower: props.document.metadata_storage_file_extension_lower,
          metadata_storage_name: props.document.metadata_storage_name,
          metadata_storage_path: props.document.metadata_storage_path,
          previous_md5_content: props.document.previous_md5_content,
    }
      });
      if (path != null) {
        let link = res.data;
        let fileName = props.document.metadata_storage_name;
        axios({
          url: link,
          method: "GET",
          responseType: "blob"
        }).then(response => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          var blob = new Blob([response.data], {
            type: "text/plain;charset=utf-8"
          });
          saveAs(blob, fileName);
        });
      }
    }

    return(
    <div className="card result">
        <div className="result-wrapper" >
            <div className="result-head">
                <div className="doc-type">
                    <img src={docType} className="doctype-icon" alt=""/>
                </div>
                <div className="result-title" onClick={handleShow}>
                  <h6 className="title-style">{props.document.metadata_storage_name}</h6>
                </div>

                <Tooltip title="Download the document" arrow>
                  <div className="result-download">
                      <img src="/images/Download.svg" alt="" className="download-icon" onClick={downloadFile}/>
                  </div>
                </Tooltip>
            </div>
            <div className="result-body">
                <div className="date-language">
                    {datestring} | {language} {discipline ? `| ${discipline}` : ''} {project ? `| ${project}` : ''}
                </div>
                <div className="result-lines" style={content ? {"height": "4.1vw"} : {"height": "auto"}}>
                  {newContent ?
                  <Highlighter highlightClassName="YourHighlightClass" searchWords={searchTag}
                  autoEscape={false} textToHighlight={newContent ? newContent : '' }
                  findChunks={(searchQ.startsWith("\"") && searchQ.endsWith("\"")) ? findChunksSearch : undefined}
                  />
                  : (<span>{content}</span>)}

                </div>
            </div>
            <Tooltip title="Keyphrases" arrow>
              <div className="result-tags" style={{"display": "flex"}}>
                  <div id="tag-container" className="tag-list" style={isActive ? taglistStyle : taglistStyle1}>{tagList}</div>
                  <div className="see-more-tags" onClick={expandSeemore}>{isActive ? <ExpandMore /> : <ExpandLess />}</div>
              </div>
            </Tooltip>
        </div>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="fullmodal" size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.document.metadata_storage_name} - Petrofac</Modal.Title>
            </Modal.Header>
            <Details keyphrases={lengthArray} detailsDoc={props.document} q={props.q} />
        </Modal>
    </div>
    );
}
