import React, { useState, useEffect } from "react";
import {decode} from 'html-entities';
import Tooltip from '@material-ui/core/Tooltip';
import Highlighter from "react-highlight-words";
import {TextField } from "@material-ui/core";

export default function SearchTranscript(props) {
    // eslint-disable-next-line
  const [initalSearchTag, setInitalSearchTag] = useState(props.q.trim());
  const [searchtext, setSearchtext] = useState('');
  const [searchTags, setSearchTags] = useState([]);
  const [isActive, setActive] = useState(true);
  const [isActiveTranscript, setActiveTranscript] = useState(true);

  if(props.selectedTab === 1) {
    props.renderTranscript(searchTags);
  }

  useEffect(() => {
    if((initalSearchTag.startsWith("\"") && initalSearchTag.endsWith("\""))) {
      // searchTags.push(initalSearchTag.substring(1, initalSearchTag.length-1));
      if(initalSearchTag.search(/\bAND\b/) > 0 || initalSearchTag.search(/\bOR\b/) > 0 || initalSearchTag.search(/\bNOT\b/) > 0){
        if(initalSearchTag.search(/\bAND\b/) > 0 || initalSearchTag.search(/\bOR\b/) > 0){
          const valueOfAndOr =initalSearchTag.replace(/"/g,'regexOperator').replace(new RegExp("\\bAND\\b"), "regexOperator").replace(new RegExp("\\bOR\\b"), "regexOperator").toLowerCase().split("regexoperator");
          for(let index in valueOfAndOr) {
            valueOfAndOr[index] = valueOfAndOr[index].trim();
          }
          setSearchTags(valueOfAndOr.filter(function (obj) { 
            return !['',' ',null, undefined].includes(obj) 
          }));
        }else{
          const valueOfNot = initalSearchTag.replace(/"/g,'regexOperator').split("NOT")[0].toLowerCase().split("regexoperator");
          for(let index in valueOfNot) {
            valueOfNot[index] = valueOfNot[index].trim();
          }
          setSearchTags(valueOfNot.filter(function (obj) {
            return !['',' ',null, undefined].includes(obj) }));
        }
      }else {
        const searchWord = initalSearchTag.substring(1, initalSearchTag.length-1).trim();
        searchTags.push(searchWord);
      }
    } else {
      setSearchTags(initalSearchTag.replace(/["']/g, '').split(" "));
    }
    // eslint-disable-next-line
  }, []);

  const onEnterButton = (event) => {
    if (event.keyCode === 13) {
      searchChange();
    }
  }

  function onChangeHandler () {
    setActiveTranscript(true);
    var searchTerm = document.getElementById("searchbox").value;
    setSearchtext(searchTerm);
  }

  const searchChange = (e) => {
   if(searchtext.length) {
    setSearchTags(searchtext.split(' '));
   }    
  }

  const searchTagHandler = (tag) => {
    setActiveTranscript(true);
    const searchIndex = searchTags.indexOf(tag);
    if(searchIndex === -1) {
      let array = decode(tag.target.innerHTML).replace(/[\n\t\s]+/g, " ").split(" ");
      array = array.filter(function(str) {
      return /\S/.test(str);
    });
    setSearchTags(array);
    }
  }

  const findChunksSearch = ({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight
  }) => {
    const chunks = [];
    const textLow = textToHighlight.toLowerCase();
    const sep = /[\s~`!@#$%^&*()-_+=\{\}\[\]|\\:;"'<,\/>.?]/;
    const singleTextWords = textLow.split(sep);

    // It could be possible that there are multiple spaces between words
    // Hence we store the index (position) of each single word with textToHighlight
    let fromIndex = 0;
    const singleTextWordsWithPos = singleTextWords.map(s => {
      const indexInWord = textLow.indexOf(s, fromIndex);
      fromIndex = indexInWord;
      return {
        word: s,
        index: indexInWord
      };
    });

    // Add chunks for every searchWord
    searchWords.forEach(sw => {
      const swLow = sw.toLowerCase();
      // Do it for every single text word
      singleTextWordsWithPos.forEach(s => {
        if (s.word === swLow) {
          const start = s.index;
          const end = s.index + swLow.length;
          chunks.push({
            start,
            end
          });
        }
      });

      // The complete word including whitespace should also be handled
      if(swLow.split(" ").length > 1) {
      for(let index in textLow) {
        let start = parseInt(index);
        let end = parseInt(index) + swLow.length;
        let sub = textLow.substring(start,end);
        let remain = textLow.substring(start,end+1);
        if(sub === swLow && (/[^a-zA-Z0-9]/.test( remain[remain.length - 1] ))) {
          chunks.push({
            start,
            end
          });
        }
      }
    }
    });


    return chunks;
  };

  let doc = props.documented.metadata_storage_file_extension_lower;
  let isCAD = (doc === '.dwg' || doc === '.dgn' || doc === '.DWG' || doc === '.DGN');
  let transcriptContent = isCAD ? props.documented.dwg_dgn_content : props.documented.translated_content;
  let highlightContent = transcriptContent ? transcriptContent : "";

  let newContent = [];
  if(searchTags[0] !== '*') {
    if(highlightContent) {
      if(searchTags.length === 1) {
        let splitedContent = highlightContent.toLowerCase().split(searchTags[0].toLowerCase(), Number.MAX_SAFE_INTEGER);
        if(splitedContent.length > 0) {
          if(splitedContent[0] === highlightContent.toLowerCase()) {
            splitedContent = [];
          }
          if(splitedContent.length > 1) {
            splitedContent.splice(0, 1);
          }
          for(let i=0; i<splitedContent.length; i++) {
            if(splitedContent[i]) {
              newContent.push(`${searchTags[0]}${splitedContent[i]}`);
            }
          }
        }
      } else {
        newContent = [];
        for (let i=0; i<searchTags.length; i++) {
          if(searchTags[i] !== '') {
            const word = searchTags[i];
            let splitedContent = highlightContent.toLowerCase().split(word.toLowerCase(), Number.MAX_SAFE_INTEGER);
            if(splitedContent.length > 0) {
              if(splitedContent[0] === highlightContent.toLowerCase()) {
                splitedContent = [];
              }
              if(splitedContent.length > 1) {
                splitedContent.splice(0, 1);
              }
              for(let i=0; i<splitedContent.length; i++) {
                splitedContent[i] = decode(splitedContent[i]).replace(/[\n\t\s]+/g, " ");
                if(splitedContent[i] !== '' || splitedContent[i] !== ' ') {
                  newContent.push(`${word}${splitedContent[i]}`);
                }
              }
            }
          }
        }
      }
    }
  }

  let transcriptBoxes = [];
  let lessThan20Words = [];
  let moreThan20Words = [];
  let threeMerge = [];

  if(newContent) {
    for(let i=0; i<newContent.length; i++) {
      if(newContent[i] && newContent[i].split(' ')) {
        if(newContent[i].split(' ').length<20) {
          lessThan20Words.push(newContent[i]);
        } else {
          moreThan20Words.push(newContent[i]);
        }
      }
    }
    for(let i=0; i<lessThan20Words.length; i+=3) {
      let three = '';
      if(lessThan20Words[i].length<50 && lessThan20Words[i] !== undefined && lessThan20Words.length > 10) {
        three = lessThan20Words[i] + lessThan20Words[i+1] + lessThan20Words[i+2] + lessThan20Words[i+3] + lessThan20Words[i+4] + lessThan20Words[i+5]; 
      } else {
        three = lessThan20Words[i] + lessThan20Words[i+1] + lessThan20Words[i+2];
      }
      threeMerge.push(three);
    }
    // eslint-disable-next-line
    transcriptBoxes = [...threeMerge, ...moreThan20Words];
  }

  let showTranscriptLess = transcriptBoxes.slice(0, 15);
  let showTranscriptMore = transcriptBoxes;
  let showTrancript = isActiveTranscript ? showTranscriptLess : showTranscriptMore; 

  const expandSeemoreTranscript = () => {
    setActiveTranscript(!isActiveTranscript);
  };

  let showTagsLess = props.keyphrases.slice(0, 15);
  let showTagsMore = props.keyphrases;
  let showTags = isActive ? showTagsLess : showTagsMore;

  let tagList = showTags.map(function(name){
    return <div className="tag">
                <span className="tagName"  onClick={(e) => searchTagHandler(e)}>{name}</span>
            </div>
    })

  const expandSeemore = () => {
    setActive(!isActive);
  };

  const paraChunkSmall = {
    maxHeight: "3vw !important",
    minHeight: "3vw !important",
  }

  const paraChunkBig = {
    maxHeight: "6vw !important",
    minHeight: "6vw !important",
  }

    return(
        <div className="search-container">
            <div className="searchbar-modal">
                <Tooltip title="Please type keywords to search in transcript" arrow>
                <TextField
                    autoComplete="off"
                    type="text"
                    id="searchbox"
                    className="form-control search-text-bar"
                    placeholder="Please type keywords to search in transcript"
                    onKeyDown={e => onEnterButton(e)}
                    onChange={onChangeHandler}
                    InputProps={{ disableUnderline: true }}
                    ></TextField>
                </Tooltip>
                <div className="searchbtn" title="Search">
                <button className="searchButton" type="submit" onClick={searchChange}>
                    <img src="/images/search_icon.svg" alt="" className="searchIcon" />
                </button>
                </div>
            </div>
            {/* <span className="occurences-title">Occurences:&nbsp;<span className="occurences">{occurences}</span></span> */}
            <span className="keyphrases-title">Keyphrases</span>
            <div className="taglist-details-page">
            {tagList.length ? tagList : <span className="no-data">Keyphrases are not available for this document.</span>}
            {props.keyphrases.length > 15 && <span className="see-more-tags-preview" onClick={expandSeemore}>{isActive ? <span className="see-more">see more...</span> : <span className="see-more">see less...</span>}</span>}
            </div>
            <div className="content-highlighter" style={!highlightContent ? { height: 'unset' } : {fontFamily: "Helvetica"}}>
            {highlightContent ? 
                showTrancript.map((para, key) => (
                <Highlighter id="transcript" className="paraChunk" highlightClassName="YourHighlightClass1" unhighlightClassName="unhighlightedText" searchWords={searchTags} autoEscape={true} textToHighlight={para} 
                findChunks={(initalSearchTag.startsWith("\"") && initalSearchTag.endsWith("\"")) ? findChunksSearch : undefined}
                style={para.split(' ').length < 10 ? paraChunkSmall : paraChunkBig} />
                )) :
                <span className="no-data">Transcript is not available for this document.</span>
            }
            {newContent.length > 15 && <span className="see-more-tags-preview" onClick={expandSeemoreTranscript}>{isActiveTranscript ? <span className="see-more">see more...</span> : <span className="see-more">see less...</span>}</span>}
            </div>
      </div>
    )
}